import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Title, Button } from "../components/Core";
import { navigate } from "gatsby";

const NotFoundPage = () => {
    return (
        <div className="dark-bg hero-section">
            {/*<FullScreenSearch/>*/}
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80">
                        <h1>404</h1>
                        <h2>Page not found</h2><br />
                        <Button onClick={() => window.location.href = 'https://www.youfibre.com/'} className="medium-button mt-5">Back to Home</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NotFoundPage;
